import styled from '@emotion/styled'
import React, { useEffect, useState } from 'react'

const TypingMessage = styled.span``

const S_TYPE = 100
const S_DEL = 50
const S_WAIT = 2000

interface TypeWrittingProps {
  heroMessages: string[]
  onEmpty?: string
}

function TypeWritter({ heroMessages, onEmpty }: TypeWrittingProps) {
  const [typing, setTyping] = useState(heroMessages[0])
  const [typingSpeed, setTypingSpeed] = useState(1000)
  const [isDeleting, setisDeleting] = useState(true)
  const [loop, setLoop] = useState(heroMessages[0].length - 1)

  const index: number = loop % heroMessages.length
  const fullMessage: string = heroMessages[index]

  //Type every letter of word from message array
  const handleTyping = () => {
    //Delete letter if are true or add if are false
    setTyping(
      isDeleting
        ? fullMessage.substring(0, typing.length - 1)
        : fullMessage.substring(0, typing.length + 1)
    )
    //Speed of delete letter   delete | add
    setTypingSpeed(isDeleting ? S_DEL : S_TYPE)

    //Once the message are complete , delete it
    if (!isDeleting && typing === fullMessage) {
      setTimeout(() => setisDeleting(true), S_WAIT)
    } else if (isDeleting && typing === '') {
      setisDeleting(false)
      setLoop(loop + 1)
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      handleTyping()
    }, typingSpeed)

    return () => clearTimeout(timer)
  })

  const def = onEmpty ?? ''
  return <TypingMessage>{typing || def}</TypingMessage>
}

export default React.memo(TypeWritter)
